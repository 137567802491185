<template>
    <div class="patient_message_popup_wrp">
  <div class="patient_message_popup_modal_wrp">
    <div class="patient_message_popup_modal_center">
      <div class="patient_message_popup_modal">
        <div class="patient_message_popup_header">
          <h3 class="patient_message_popup_heading">Reply On Messages of Patients</h3>
        </div>
        <div class="patient_message_popup_body">
            <div class="rply_message_of_area mt-2">
                <form @submit.prevent="sendChatRequestConfirm">
                    <div class="form-group">
                        <textarea type="text" class="form-control" v-validate="'required'" v-model="message" name="message"
                            v-bind:class="{ 'is-invalid': errors.has('message') }" data-vv-as="message" maxlength="300"
                            rows="5"></textarea>
                        <small class="invalid-feedback" v-if="errors.has('message')">{{ errors.first('message') }}</small>
                    </div>
                    <div class="btn-group">
                        <span @click="closeChatRequestConfirm" class="btn remove_yes_btn">Cancel</span>
                        <button type="submit" class="btn remove_yes_no">Reply</button>
                    </div>
                </form>
    </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
/*eslint-disable */
const $ = window.jQuery = require('jquery');
import { bus } from '@/main';
import toast from '@/services/toast';
export default {
    name: 'reply-message-of-patient',
    components: {},
    props: ['patientID'],
    data() {
        return {
            message: '',
        }
    },
    computed: {

    },
    mounted() {
    },
    methods: {
        sendChatRequestConfirm(e) {
            this.$validator.validateAll().then((result) => {
                this.submitted = true;
                if (result) {
                    let data = {
                        doctor_message: this.message,
                    }
                    this.$store.dispatch('replyOnPatientMsgMethod', { data: { data: data, id: this.patientID } })
                        .then((res) => {
                            toast.success("Request processed successfully" || res.data.msg);
                            this.closeChatRequestConfirm();
                            // let notificationPayload = {
                            //     type: 'doctor_message',
                            //     data_id: this.groupId,
                            //     message: "You have a new direct message"
                            // }
                            // this.$store.dispatch('postNotificationFunc', notificationPayload)
                            // toast.success(res.data.user_msg || res.data.msg);
                            // this.$store.dispatch('getpatientMessageListMethod').then((res) => {
                            //     bus.$emit('replyAgainMessageDataBus', res.data.data);
                            //     this.closeChatRequestConfirm();
                            // })
                        }).catch((error) => {
                            toast.error(error.data.user_msg || error.data.msg);
                        })
                    return;
                }
            });
        },
        closeChatRequestConfirm() {
            bus.$emit('replyAgainMessageDataBus', false)
            document.body.classList.remove('popup_open_body')
        },
    }
}
</script>

<style lang="scss" scoped>
.patient_message_popup_wrp{
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.92);
    left: 0;
    right: 0;
    z-index: 1000;
    overflow-y:auto;
    .patient_message_popup_modal_wrp {display: table;width: 100%;height: 100%;}
    .patient_message_popup_modal_center {display: table-cell;vertical-align: middle;}
    .patient_message_popup_body {padding: 0px 15px;min-height:150px;}
    .patient_message_popup_modal{
        background: #000;
        width: 100%;
        max-width:620px;
        margin: 0px auto;
        border-radius: 5px;
        padding:0;
        font-family: 'MyriadProRegular','Lato',sans-serif;
        color: #ffffff;
        -webkit-box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
        box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
    }
    .patient_message_popup_header{padding:12px 15px;position: relative;border-bottom:1px solid rgba(255, 255, 255, 0.2);;}
    .patient_message_popup_heading {
        font-size: 23px;
        color: #ffffff;
        font-family: 'MyriadProRegular','Lato',sans-serif;
        text-align: left;
        margin: 0;
        line-height: 1.25;
    }
}
.rply_message_of_area {
    h3 {
        font-size: 22px;
        color: #ffffff;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        margin: 0;
    }

    .remove_yes_btn {
        font-size: 16px;
        background: #ff7070;
        color: #fff;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        padding: 10px 5px;
        min-width: 100px;
        line-height: 1;
        border-radius: 3px !important;
        outline: none !important;
        box-shadow: none !important;

        &:hover {
            background: #b41818;
            color: #fffcc4;
        }
    }

    .remove_yes_no {
        font-size: 16px;
        background: #459238;
        color: #fff;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;
        padding: 10px 5px;
        min-width: 100px;
        line-height: 1;
        margin-left: 10px;
        border-radius: 3px !important;
        outline: none !important;
        box-shadow: none !important;

        &:hover {
            background: #87cefa;
            color: #22292f;
        }
    }

    .form-control {
        background: rgba(255, 255, 255, 0.1) !important;
        border: none;
        color: #fff;
    }
}
</style>