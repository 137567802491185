<template>
    <div class="custom_stylig_for_table">
        <div class="page_main_header_section pb-3">
            <div class="row">
                <div class="col-auto pr-0">
                    <router-link class="btn back_btn" :to="{ name: 'DashboardMain' }">
                        <i aria-hidden="true" class="fa fa-chevron-left"></i>
                    </router-link>
                </div>
                <div class="col pl-0">
                    <h3 class="page_main_heading"> Patient One-Time Messages</h3>
                </div>
            </div>
        </div>
        <table class="table" v-if="patientMsgListState">
            <thead>
                <tr>
                    <th width="28%">Patients Name</th>
                    <th class="text-center">Patients Message</th>
                    <th class="text-right">My Message</th>
                </tr>
                <tr class="empty_cell">
                    <th colspan="3"></th>
                </tr>
            </thead>
            <tbody v-if="patientMsgListResult.length > 0">
                <tr v-for="item in patientMsgListResult" :key="item.id">
                    <td>{{ item.user.first_name }} {{ item.user.last_name }}</td>
                    <td class="text-center"><span class="patient_msg">{{ item.patient_message }}</span></td>
                    <td class="text-right">
                        <template v-if="item.doctor_message"><span class="doctor_msg">{{ item.doctor_message }}</span></template>
                        <template v-else><span @click="replyMsgOfPatientMethod(item.id)" class="reply_btn">Reply</span></template>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="3" class="text-center">You don't have any patient messages</td>
                </tr>
            </tbody>
        </table>
        <div class="compontent-loader" v-else>
            <div class="spinner"></div>
        </div>
        <reply-message :patientID="patientId" v-if="replyMsgPopupState"></reply-message>
    </div>
</template>
<script>
/* eslint-disable */
import replyMessageOfpatient from '@/components/private/appointments/reply-on-message-of-patient/';
import { bus } from '@/main';
export default {
    props: [],
    components: {
        'reply-message' : replyMessageOfpatient,
    },
    data() {
        return {
            patientMsgListState: null,
            patientMsgListResult: [],
            replyMsgPopupState:false,
            patientId:''
        };
    },
    mounted() {
        this.getPatientMsgMethod();
        bus.$on('replyAgainMessageDataBus', (data) => {
            this.replyMsgPopupState = data;
            this.getPatientMsgMethod();
        });
    },
    methods: {
        getPatientMsgMethod(){
            this.$store.dispatch('getPatientsOneTimeMsgListAction').then((res) => {
                this.patientMsgListState = res.data
                this.patientMsgListResult = this.patientMsgListState.data
            })
        },
        replyMsgOfPatientMethod(id){
        this.replyMsgPopupState = true;
        this.patientId = id
        document.body.classList.add('popup_open_body')
        },
    }
}
</script>
<style lang="scss" scoped>
.custom_stylig_for_table {
    .table {
        margin: 0px;
        letter-spacing: 0.5px;
        color: #ffffff;
        border-collapse: separate;
        border-spacing: 0 3px;

        thead {
            th {
                border-bottom: 0;
                font-size: 18px;
                padding: 10px 8px;
                font-family: 'MyriadProRegular', sans-serif;
                text-transform: uppercase;
                border-top: 0;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all ease .5s;
                background: rgba(228, 233, 237, .1);
                color: #87cefa;
            }

            .empty_cell th {
                padding: 0px !important;
                background: none !important;
            }
        }

        tbody {
            td {
                color: #fff;
                vertical-align: middle;
                padding: 10px 8px;
                font-size: 20px;
                font-family: 'MyriadProRegular', sans-serif;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all ease .5s;
                height: 70px;
                line-height: 1.3;
                border-top: 0;
                background: rgba(228, 233, 237, .1);
                @media only screen and (max-width:767px){font-size:16px;}

                &.symptoms_item span:last-child i {
                    display: none;
                }

                a {
                    color: #fff;
                    text-decoration: none;

                    &:hover {
                        color: #ffd400;
                    }
                }
                .patient_msg {    
                    display: inline-block;
                    max-width: 400px;
                    font-size: 14px;
                    text-align: justify;
                    background: #b1d4e5;
                    padding: 10px;
                    border-radius: 5px;
                    color: #000;
                    font-weight: 600;
                }

                .doctor_msg { 
                    display: inline-block;
                    max-width: 380px;
                    font-size: 14px;
                    text-align: justify;
                    background: #ffb6c1;
                    padding: 10px;
                    border-radius: 5px;
                    color: #000;
                    font-weight: 600;
                }
            }
        }

        .reply_btn {
            color: #ffd400;
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                color: #87cefa;
            }
        }
    }
}
</style>